import React, {Fragment, lazy} from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './Layouts/Loader/Loader';
import "./index.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './Utils/ProtectedRoute';

const Auth = lazy(() => import('./components/Authentication/LoginAuth/auth'));
const App = lazy(() => import('./components/app'));
const Error401 = lazy(() => import('./components/Authentication/errorPage/Error401/Error401'));
const Error403 = lazy(() => import('./components/Authentication/errorPage/Error403/Error403'));
const Error404 = lazy(() => import('./components/Authentication/errorPage/Error404/Error404'));
const Error503 = lazy(() => import('./components/Authentication/errorPage/Error503/Error503'));
const ForgotPassword = lazy(() => import('./components/Authentication/Forgot Password/ForgotPassword'));
//const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));

const Profile = lazy(() => import('./components/pages/Profile/Profile'));
const Entertainments = lazy(() => import('./components/pages/Entertainments/Entertainments'));
const TabReport = lazy(() => import('./components/pages/Tab/TabReport'));
const Orders = lazy(() => import('./components/pages/Order/Orders'));
const CustomerRegistry = lazy(() => import('./components/pages/CustomerRegistry/CustomerRegistry'));
const CreateEvent = lazy(() => import('./components/pages/Event/CreateEvent'));

const Error400 = lazy(() => import('./components/Authentication/errorPage/Error400/Error400'));
const Error500 = lazy(() => import('./components/Authentication/errorPage/Error500/Error500'));
const AuthenticationPage = lazy(() => import('./components/AuthenticationPage'));
const ErrorPages = lazy(() => import('./components/ErrorPages'));
const AuthLogin = lazy(() => import('./components/Authentication/LoginAuth/AuthLogin'));

const DataMultipleSelectStyle = lazy(() => import('./Data/Pages/Forms/FormAdvanceData/DataMultipleSelectStyle'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(

    <React.StrictMode>
        <Fragment>
            <BrowserRouter>
                <React.Suspense fallback={<Loader />}>
                    <Routes>

                        <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
                            <Route path={`${process.env.PUBLIC_URL}/`} element={<AuthLogin />} />
                            <Route path={`${process.env.PUBLIC_URL}/`} element={<AuthenticationPage />}>
                            </Route>
                        </Route>
                        <Route path="/" element={<App />}>

                            <Route path={`${process.env.PUBLIC_URL}/pages/profile`} element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            } />

                            <Route path={`${process.env.PUBLIC_URL}/pages/entertainments`} element={
                                <ProtectedRoute>
                                    <Entertainments />
                                </ProtectedRoute>
                            } />

                            <Route path={`${process.env.PUBLIC_URL}/pages/orders`} element={
                                <ProtectedRoute>
                                    <Orders />
                                </ProtectedRoute>
                            } />

                            <Route path={`${process.env.PUBLIC_URL}/pages/customer_registry`} element={
                                <ProtectedRoute>
                                    <CustomerRegistry />
                                </ProtectedRoute>
                            } />

                            <Route path={`${process.env.PUBLIC_URL}/pages/reports`} element={
                                <ProtectedRoute>
                                    <TabReport />
                                </ProtectedRoute>
                            } />

                            <Route path={`${process.env.PUBLIC_URL}/pages/create_event`} element={
                                <ProtectedRoute>
                                    <CreateEvent />
                                </ProtectedRoute>
                            } />

                        </Route>

                        {/* Authentication Error Pages */}
                        <Route path={`${process.env.PUBLIC_URL}`} element={<ErrorPages />}>
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error400`} element={<Error400 />} />
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error401`} element={<Error401 />} />
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error403`} element={<Error403 />} />
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error404`} element={<Error404 />} />
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error500`} element={<Error500 />} />
                            <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error503`} element={<Error503 />} />
                            <Route path="*" element={<Error500 />} />
                        </Route>

                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </Fragment>

    </React.StrictMode>

);

reportWebVitals();


/*
<Route path={`${process.env.PUBLIC_URL}/`} element={<AuthenticationPage />}>
                                <Route path={`${process.env.PUBLIC_URL}/authentication/forgotpassword`} element={<ForgotPassword />} />
                            </Route>
 */